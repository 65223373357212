@font-face {
  font-family: "Kufam";
  src: url("./assets/fonts/Kufam-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Mulish";
  src: url("./assets/fonts/Mulish-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/raleway-regular.ttf") format("truetype");
}

/* 
==========
DEFAULT
==========
*/
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
==========
NAVBAR
==========
*/
.navbar ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  /* width: 50%; */
}

nav a {
  text-decoration: none;
  color: #ffffff;
  font-family: kufam;
  /* padding: 1rem; */
  /* font-size: 20px; */
}

/* nav a:hover,
nav a:active,
nav a:focus {
  color: #0031a5;
  text-decoration: underline;
  text-decoration-color: #f38820;
} */
/* .navbar ul li :active,
.navbar ul li :focus,
.navbar ul li :hover {
  background-color: grey;
  height: 3rem;
} */
/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  position: absolute;
  width: 80%;
  left: 9%;
  top: 10%;
  border-radius: 5px;
} */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5rem 10%;
}

.navbar img {
  max-width: 170px;
}

.navbar ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: black;
  border-radius: 30px;
  height: 4rem;
  align-items: center;
  /* padding: 1rem; */
}

.navbar ul li {
  margin: 0 10px;
  /* width: min-content;  */
}

.navbar ul li a {
  padding: 10px 15px;
}

.active-item,
.navbar ul li a:hover {
  background-color: #393939;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.navbar button {
  border-radius: 40px;
  height: 4rem;
}

/* .navbar ul li:hover {
  background-color: grey;
} */
/* 
==========
Buttons
==========
*/
.btn-primary {
  font-size: medium;
  color: white;
  background-color: #ee6f21;
  border-color: transparent;
  border-radius: 2px;
  padding: 0.75em 2.5em;
  width: fit-content;
  cursor: pointer;
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  font-size: medium;
  color: white;
  background-color: rgb(47, 47, 47);
  border-color: transparent;
  padding: 0.75em 2.5em;
  width: fit-content;
  cursor: pointer;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

/* .navbar button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
} */

/* 
            =================
            HOME HEADER
            =================
              */
.header_text h1 {
  font-family: kufam;
  font-weight: 200;
}

.header_text h6 {
  font-size: 1.5em;
  font-weight: 100;
}

.header_numbers {
  font-family: kufam;
  font-weight: 900;
  color: #134a87;
}

.header_paragraph {
  color: #134a87;
}

/* 
=================
HOME HEADER CARD
=================
*/
.header_card {
  background-color: transparent;
  /* border-radius: 5px; */
  width: 12vw;
  padding: 1.5em 0;
  text-align: center;
}

.header_logo {
  width: 3em;
}

/* 
=================
HOME Title
=================
*/
.home_title {
  display: flex;
  gap: 0.7rem;
  margin-left: 10%;
  margin-bottom: 5rem;
  margin-top: 5rem;
}

.home_title h2 {
  color: #134a87;
  font-family: kufam;
}

.rectangle {
  width: 15px;
}

.underline {
  border-bottom: #f38820 4px solid;
}

/* 
=================
First SECTION
=================
*/
.header_badge {
  width: 3rem;
}

.badge_container {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.badge_container h6,
p {
  margin: 0;
}

/* 
=================
FOOTER
=================
*/
.footer {
  display: flex;
  height: 60vh;
}

.footer_contact {
  background-color: #0031a5;
  width: 50%;
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.footer_coords {
  width: 50%;
  border-top: #0031a5 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 3rem; */
}

.footer_coords h2 {
  color: #0031a5;
  font-family: sans-serif;
  font-weight: 500;
}

.footer_coords p {
  color: #0031a5;
}

.footer_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 500;

}

.footer_content h2 {
  font-weight: 600;
}

.footer_content p {
  margin-block: 0.5rem;
}

.footer_socials {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-block: 0.5rem;
  /* margin-bottom: 8rem; */
}

.footer_socials img {
  width: 17px;
}

/* .footer_rights {
  display: flex;
} */
.scroll_top_sec {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.scroll_top_arrow {
  width: 3%;
  cursor: pointer;
}

.footer a {
  text-decoration: none;
}

.copyright-desktop {
  display: block;
  color: #ffffff;
  margin-top: 5rem !important;
}

.copyright-mobile {
  text-align: center;
  color: #ffffff;
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
  display: none;

}

@media only screen and (max-width: 768px) {

  .copyright-desktop {
    display: none;

  }

  .copyright-mobile {
   
    display: block;
   
  }
}

@media only screen and (max-width: 768px) {
  .navbar {
    display: none !important;
  }

  .header_cards {
    gap: 1rem !important;
  }

  .header_card {
    width: 25vw;
  }

  .header_paragraph {
    font-size: 12px;
  }

  .home_title {
    margin-left: 0.5rem;
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
  }

  .home_title h2 {
    font-size: 24px;
  }

  .first_section button {
    margin-top: 1rem;
    font-size: small;
  }

  .second_sec_content button {
    font-size: small;
  }

  .footer {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    margin-top: 1rem;
  }

  .footer_contact {
    width: 100%;
  }

  .footer_coords {
    width: 100%;
  }

  .footer_content {
    margin: 2rem;
  }

  .scroll_top_sec {
    display: none;
  }

  .scroll_mobile img {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1350px) {
  .navbar {
    margin: 1rem 2rem;
  }

  .navbar ul {
    height: 3rem;
  }

  .navbar ul li a {
    padding: 5px 5px;
  }

  .active-item,
  .navbar ul li a:hover {
    border-radius: 10px;
  }

  .navbar button {
    height: 3rem;
  }
}

@media (min-width: 768px) and (max-width: 1030px) {
  .navbar ul li {
    font-size: 10px;
    margin: 5px;
  }

  .btn-primary {
    padding: 0.75em 1em;
    font-size: 12px;

  }
}