.mobile-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  padding: 10px;
  background-color: transparent;
  color: #fff;
  /* position: absolute; */
  width: 95%;
}

.hamburger-icon {
  font-size: 30px;
  cursor: pointer;
  color: grey;
}
.logo {
  padding-top: 1rem;
}
.logo-hamburger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 10px;
}
.logo img {
  width: 130px;
}

.button {
  background-color: rgb(238, 111, 33);
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  width: 90%;
}
.mobile-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(222, 217, 217, 0.8);
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}
.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  margin-top: 60%;
}

.mobile-menu ul li {
  margin-bottom: 10px;
  font-size: 18px;
  color: #fff;
}
.mobile-menu a {
  text-decoration: none;
  color: #134a87;
  font-size: 24px;
  font-family: Kufam;
}
.hamburger-icon,
.logo,
.button {
  z-index: 10000;
}
.mobile-menu a.active {
  color: #f38820;
}
