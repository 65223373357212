/*
==============================contact Page==================================
*/
.contact-container {
  position: relative;
  display: flex;
  margin-left: 10%;
  padding: 20px;
  max-width: 80%;
}

.contact-info {
  background-color: #003cca;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 5rem;
  width: 50%;
}

.contact-info h2 {
  font-family: raleway;
  font-size: 2rem;
  font-weight: bolder;
}

.contact-info h4 {
  font-family: raleway;
  font-size: 1.5rem;
}

/* .contact-info p {
  font-family: raleway;                             to check later
} */
.contact-form {
  background-color: #f8f9fa;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2rem;
  width: 50%;
}

.contact-btn {
  background-color: #003cca;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
}

.contact-socials {
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
}

.contact-socials img {
  width: 20px;
}

.contact-link {
  color: white;
  text-decoration: none;
}

.contact-cube {
  position: absolute;
  left: -5%;
  top: -15%;
  width: 13%;
  z-index: -1;
}

.contact-cubic {
  position: absolute;
  bottom: -14%;
  right: -5%;
  width: 13%;
  z-index: -1;
}

/*
==============================ContactForm component===========================
*/
.contact-form-group {
  margin: 1rem;
}

.contact-form-field {
  border: transparent;
  width: 100%;
  /* padding: 1rem; */
  height: 3rem;
  padding: 0;
  margin: 0;
}

.contact-text-area {
  height: 10rem;
}

.contact-form-field {
  padding-left: 1rem;
  font-family: Raleway;
}

.contact-input-field-error {
  border: 1px solid red;
}

.contact-input-field-error::placeholder {
  color: #e83b3bdd;
}

.error-message {
  color: red;
  font-family: Raleway;
  font-size: 14px;
}

.contact-text-area {
  padding-top: 1rem;
}

Form>div>label {
  font-family: Raleway;
  /* font-size: medium; */
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    flex-wrap: wrap;
    /* margin-left: 0%; */
    padding: 0%;
  }

  .contact-info {
    width: 100%;
    padding: 1rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
  }

  .contact-form {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 1rem;
    width: 100%;
  }

  .contact-container img {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .contact-form {
    padding: 0.5rem;
  }
}