.swiper-container {
  max-width: 90vw;
  position: relative;
  margin-left: 4rem;
  margin-right: 4rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.swiper-container .arrow {
  position: absolute;
  top: 40%;
  /* margin-top: -5px; */
  /* z-index: 99; */
}
.swiper-container .arrow.arrow-prev {
  left: -5%;
  cursor: pointer;
  z-index: 2;
}
.swiper-container .arrow.arrow-next {
  right: -5%;
  cursor: pointer;
  z-index: 2;
}
.scrollbar {
  background-color: rgb(230, 223, 223);
  height: 1%;
  width: 70%;
  position: absolute;
  margin-top: 2rem;
  left: 15%;
  border-radius: 30px;
}
.swiper-scrollbar-drag {
  background-color: rgba(243, 136, 32, 1) !important;
}

.slide-content {
  position: relative;
  overflow: hidden;
}

.slide-content img {
  display: block;
  width: 100%;
  height: auto;
}

.over-lay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  box-sizing: border-box;
}

.city {
  font-size: 16px;
  font-weight: bold;
  font-family: Kufam;
}

.navigate-arrow {
  width: 2.5rem !important;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .arrow-prev img,
  .arrow-next img {
    width: 50%;
  }
  .swiper-container .arrow.arrow-next {
    right: -40%;
  }
  .swiper-container .arrow.arrow-prev {
    left: -20%;
  }
}
