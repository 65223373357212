/* .project-detail {
  padding: 16px;
}

.project-detail-content {
  display: flex;
  gap: 16px;
}

.project-images-vertical {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.project-images-vertical img {
  width: 100px;
  height: 100px;
}

.project-image-slider {
  flex: 1;
} */

/* project-detail.css */
.project-detail {
  padding: 16px;
  margin-bottom: 3rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-family: Kufam;
  font-size: 18px;
  margin-bottom: 2rem;
  margin-left: 5rem;
  font-weight: 400;
}

.breadcrumb a {
  text-decoration: none;
  color: #a4a4a4;
  padding: 0 5px;
}

.breadcrumb-active {
  color: #2f2f2f !important;
}

.breadcrumb span {
  padding: 0 5px;
  color: #f38820;
}

.project-detail-content {
  display: flex;
  justify-content: center;
  margin: 0 5rem;
  gap: 16px;
  /* flex-wrap: wrap; */
}

.project-images-vertical {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 400px;
  /* Set a fixed height for the container */
  justify-content: space-between;
  /* Distribute images evenly */
}

.project-images-vertical img {
  width: 100px;
  height: 100px;
  /* flex: 1; */
  object-fit: cover;
  /* Ensure the images fit the container */
}

.project-image-slider {
  /* flex: 2; */
  height: 400px;
  /* Set the same height for the slider */
  width: 30vw;
  /* hereeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */
  position: relative;
}

.project-detail-text {
  flex: 1;
  /* padding-left: 16px; */
  display: flex;
  flex-direction: column;
}

.project-title {
  font-family: Mulish;
  font-size: 32px;
  font-weight: 800;
  color: #f38820;
}

.project-sub-title {
  font-family: Mulish;
  font-size: 22px;
  font-weight: 600;
  color: #393939;
}

.project-description {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
}

.project-video {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}
.projects-header {
  font-family: kufam;
  font-size: 20px;
  color: #2a4b9a;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .project-detail-content {
    display: flex;
    justify-content: center;
    margin: 0 3rem;
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .breadcrumb {
    margin-bottom: 1rem;
    margin-left: 0;
  }

  .project-detail-content {
    flex-direction: column;
    margin: 0;
  }

  .project-images-vertical {
    flex-direction: row;
    overflow-x: auto;
    /* Allow horizontal scroll for images */
    height: auto;
    justify-content: center;
  }

  .project-images-vertical img {
    width: 80px;
    height: auto;
  }

  .project-detail-text {
    padding-left: 0;
    padding-top: 16px;
  }

  .project-detail-content {
    flex-wrap: wrap;
  }

  .project-image-slider {
    width: 100vw;
  }
}
