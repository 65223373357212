.title_image {
  margin: 0 auto;
  display: block;
  width: 80%;
  /* max-width: 80%; */
  /* margin-left: 10%;
  margin-top: 15%; */
  padding: 20px;
}

.title_wrapper {
  /* margin-top: 15rem; */
  position: relative;
  text-align: center;
}

.title_text {
  font-family: kufam;
  font-weight: 400;
  font-size: 55px;
  /* position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%); */
  color: white;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.paragraph_text {
  /* position: absolute; 
   top: 60%;
  left: 50%;
   transform: translate(-50%, -50%); */
  font-family: Mulish;

  color: white;
  font-size: 18px;
  text-align: center;
}

.text-container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 768px) {
  .title_wrapper {
    margin-top: 0;
  }

  .title_text {
    font-size: 22px;
  }

  .paragraph_text {
    display: none;
  }

  .text-container {
    transform: translate(-50%, -20%);
  }
}


@media (min-width: 768px) and (max-width: 1010px) {
  .title_text {
    font-size: 28px;
  }

  .paragraph_text {
    font-size: 16px;
  }

  .text-container {
    transform: translate(-50%, -30%);
    -webkit-transform: translate(-50%, -30%);
    -moz-transform: translate(-50%, -30%);
    -ms-transform: translate(-50%, -30%);
    -o-transform: translate(-50%, -30%);
  }

}