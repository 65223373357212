.about_card {
  padding: 1rem;
  width: 400px;
  height: 250px;
  text-align: center;
  border: solid 0.84px #dcdcdc;
  border-radius: 5px;
}
.about_card_paragraph {
  color: #808080;
}
.about_card img {
  margin-top: 2rem;
}
.about_card h3 {
  margin: 0.5rem 0;
}
@media only screen and (max-width: 768px) {
  .about_card {
    width: 300px;
    height: 200px;
  }
  .about_card img {
    margin-top: 0;
  }
}
