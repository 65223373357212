.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin-left: 11%; */
  /* margin-right: 10%; */
}
.categories-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.categories-filter button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin: 0 10px;
  padding: 0;
  color: #134a87;
  text-decoration: none;
}

.categories-filter button.active {
  color: orange;
  border-bottom: 2px solid orange;
}
.show-more-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Adjust as needed */
}

.show-more-button {
  background-color: transparent;
  color: orange;
  border: 2px solid orange;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.show-more-button:hover {
  background-color: orange;
  color: white;
}
