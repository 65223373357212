.wrapper {
  position: relative;
  /* max-height: 70vh !important; */
  /* max-width: 20%; */
}
/* .swiper-wrapper {
  max-height: 70vh;
} */
.image_container {
  max-height: 70vh;
}
.wrapper .arrow-btn {
  position: absolute;
  top: 40%;
  z-index: 2;
}
.wrapper .arrow-btn.arrow-left {
  left: 0%;
  cursor: pointer;
}
.wrapper .arrow-btn.arrow-right {
  right: 0%;
  cursor: pointer;
}
/* .paginate {
  color: orange;
} */
.swiper-pagination {
  margin-top: 10rem;
  margin-left: 2rem;
  /* display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end; */
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: rgb(223, 219, 219);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  border-radius: 5px;
  background: rgb(243, 136, 32);
}
.title_container {
  background-color: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(0.5px);
  width: 40%;
  margin: 1.5em;
  /* padding-left: 1em;
  padding-bottom: 1em; */
  position: absolute;
  bottom: 0%;
  padding: 1em;
}
.title_container h3 {
  margin: 0;
}
.title_container p {
  color: grey;
  font-size: 14px;
}
.marbre_arrow {
  position: absolute;
  width: 20%;
  right: -20%;
  bottom: 0%;
}
.swiper-slide:not(.swiper-slide-active) .title_container {
  display: none;
}
.image_container img {
  width: 100%;
}
.swiper-slide:not(.swiper-slide-active) .image_container img {
  height: 65vh;
}
.shake {
  display: flex;
  flex-direction: column-reverse;
}
@media only screen and (max-width: 768px) {
  .arrow-left img,
  .arrow-right img {
    width: 50%;
  }
  .wrapper .arrow-btn.arrow-left {
    /* left: -2%; */
    display: none;
  }
  .wrapper .arrow-btn.arrow-right {
    /* right: -13%; */
    display: none;
    
  }
  .swiper-container {
    margin-bottom: 4rem;
  }
}
