.about_third_sec {
  position: relative;
}

.about_second_sec {
  position: relative;
}

.about_first_sec h2,
.about_second_sec h2,
.about_third_sec h2,
.about_fourth_sec h2 {
  font-family: kufam;
  font-size: 50px;
  color: #2a4b9a;
  text-align: center;
}

.oranged_heading {
  color: #f38820;
}

.paragraph_wrapper {
  width: 50%;
  margin: 0 auto;
}

.centered_paragraph {
  text-align: center;
  color: #717171;
  margin-bottom: 4rem;
}

.about_first_sec_content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  /* gap: 4rem; */
  margin-left: 5rem;
  margin-right: 5rem;
}

.about_first_sec_text {
  width: 40%;
  padding-right: 1rem;
  /* padding: 3rem; */
}

.about_first_sec_text h3 {
  font-family: Mulish;
  font-weight: 800;
  font-size: 32px;
}

.about_first_sec_text p {
  line-height: 38px;
  color: #717171;
  font-family: Mulish;
  font-size: 20px;
}

.about_first_sec_text button {
  margin: 2rem 0;
}

.about_second_sec_content {
  display: flex;
  align-items: center;
  padding: 5rem;
}

.about_second_sec_text {
  background-color: #f8fafc;
  border-radius: 10px;
  width: 100%;
  height: fit-content;
  padding: 2rem;
  height: 350px;
  margin-top: 2rem;
}

.about_second_sec_text h3 {
  margin: 0;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
}

.about_second_sec_text p {
  margin-top: 2rem;
  font-size: 22px;
  line-height: 38px;
  color: #717171;
}

.cards_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding: 0 50px;
  /* Adjust this value as needed */
  margin-bottom: 2rem;
}

.cube {
  width: 10%;
  position: absolute;
  bottom: -10%;
  right: 0%;
}

.tube {
  width: 15%;
  position: absolute;
  top: 0%;
  left: 0%;
}

.cubic {
  width: 15%;
  position: absolute;
  top: 0%;
  right: 0%;
}

.notre_team {
  /* display: flex;
  justify-content: center; */
  position: relative;
  /*10% 150px 250px*/
  margin-left: 15vw;
}

.video-rectangle-position {
  position: absolute;
  bottom: -2%;
  left: -1%;
  z-index: -1;
}

/*
====================ABOUT BLUE BACKGROUND====================
*/
.about-image-container {
  margin-top: 5rem;
  position: relative;
  /* height: 600px; */
  width: 100%;
  overflow: hidden;
}

.background-image {
  width: 100%;
  display: block;
}

/* .text-overlay {
  position: absolute;
  top: 20%;
  transform: translate(50%, -50%);
  align-items: center;
  color: white;
  font-size: 24px;
  -webkit-transform: translate(50%, 50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
}

.text-overlay h2 {
  margin: 0;
  color: white;
}

.text-overlay p {
  margin: 0;
} */

.text-overlay {
  position: absolute;
  top: 20%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-overlay h2 {
  margin: 0;
  color: white;
}

.text-overlay p {
  margin: 0;
}


.about-video {
  /* position: absolute; */
  /* width: 40%; */
  /* height: 100%; */
  /* bottom: -30%;
  left: 30%; */
  margin-top: -12%;
  /*-15%*/
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 5rem;
}

.video-rectangle {
  position: absolute;
  left: 900px;
  bottom: -5%;
  z-index: -1;
}

/*
==============Grey Card===============
*/
.grey-card {
  background-color: #f9f9f9;
  padding: 4rem;
  width: 300px;
  position: relative;
  border-radius: 6px;
}

.card-position {
  position: absolute;
  /* bottom: 25%;
  left: 50%; */
  /* 150px+700px */
  top: 20%;
  left: 650px;
}

.card-rectangle {
  width: 25%;
  position: absolute;
  z-index: -1;
  top: -3%;
  right: -3%;
}

.grey-card button {
  margin-top: 1rem;
}


/*
==============Breadcrumbs===============
*/

.breadcrumbs {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  color: #134A87;
}

.item {
  text-transform: capitalize;
  padding: 0 10px;
  cursor: pointer;
  font-family: Kufam;
  font-weight: 400;
}

.item:not(:last-child)::after {
  content: '|';
  color: #F38820;
  margin-left: 18px;
}



@media (min-width: 768px) and (max-width: 1400px) {
  .about_first_sec_text {
    width: auto;
  }

  .text-overlay {
    left: 50%;
  }

  .text-overlay h2 {
    font-size: 40px;
  }

  .grey-card,
  .text-overlay p {
    display: none;
  }

  .about_second_sec_content {
    display: block;
    padding: 1rem;
    text-align: center;
    width: 90%;
  }

}

@media only screen and (max-width: 768px) {
  .about_second_sec_content {
    display: block;
    padding: 1rem;
    text-align: center;
  }

  .about_second_sec_text {
    width: auto;
    margin-top: 0;
    height: auto;
  }

  .about_first_sec_text {
    width: auto;
  }

  .notre_team {
    display: none;
  }

  .about_first_sec_image img {
    width: 300px;
  }

  .about_second_sec_image img {
    width: 300px;
  }

  .about_first_sec_content {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .notre_team_mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .about_first_sec h2,
  .about_second_sec h2,
  .about_third_sec h2,
  .about_fourth_sec h2 {
    font-size: 24px;
  }

  .about_first_sec_text h3 {
    font-size: 24px;
    /* text-align: center; */
  }

  .paragraph_wrapper {
    width: 80%;
  }

  .centered_paragraph {
    font-size: inherit;
    margin-bottom: 2rem;
  }

  .about_first_sec_text p {
    font-size: inherit;
    line-height: inherit;
  }

  .about_second_sec_text p {
    font-size: inherit;
    line-height: inherit;
  }

  .background-image {
    display: none;
  }

  .breadcrumbs{
    display: none;
  }
}