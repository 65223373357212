.image-slider-wrapper {
  /* position: relative; */
  width: 100%;
  height: 100%;
}

.image-slider-arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.image-slider-arrow-left {
  left: 0px;
}

.image-slider-arrow-right {
  left: 350px;
}

.custom-btn {
  width: 3rem;
}
.image-container img {
  height: 424px;
}
/* .image-container {
  width: 30%;
} */
