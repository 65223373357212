.projects-categories {
  width: fit-content;
  margin-bottom: 20px;
  border: 1px solid #ccc; /* Add border to both categories */
  border-radius: 5px; /* Rounded corners */
}

.projects-categories button {
  background: none;
  border: none; /* No individual button borders */
  cursor: pointer;
  font-size: 16px;
  padding: 10px 20px;
  color: #333; /* Grey text */
}

.projects-categories button.active {
  background-color: #134a87; /* Blue background for active */
  color: white; /* White text for active */
}
.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.filter-center {
  display: flex;
  justify-content: center;
}
