/* 
==========
HEADER
==========
*/
/* .header {
  background-image: url("../../assets/backgroundImages/homeHeader.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_text {
  display: flex;
  flex-direction: column;
  width: 30%;
  text-align: center;
}
.header_text h6 {
  margin: 0;
  color: #ffffff;
  font-family: kufam;
}
.header_text h1 {
  margin: 0;
  color: #ffffff;
}
.header_text p {
  margin: 0;
  color: #ffffff;
}
.header_main_text {
  border: #ffffff solid 1px;
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
.horizontal-line {
  border: 2px;
  height: 1.5px;
  width: 50px;
  background-color: #ffffff;
}
.header_socials {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 5%;
}
.header_socials img {
  width: 20px;
} */
.header_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5rem;
}

.header_text h1 {
  font-family: Kufam;
  color: #134a87;
  margin: 0.5rem 0;
}

.header_text p {
  font-family: Mulish;
  color: grey;
}

.oranged-text {
  color: #f38820;
}

.header_socials {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: fixed;
  right: 1%;
  top: 40%;
  z-index: 2;
}

.header_cards {
  background-color: rgba(255, 255, 255, 0.9);

  border-radius: 40px;
  display: flex;
  justify-content: center;
  gap: 2rem;
  position: absolute;
  bottom: -10%;
  left: 30%;
}

/*
=============SOCIALS=============
*/
.header_socials img {
  width: 20px;
}

.home-video {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .header_cards {
    left: auto;
  }

  .header_text {
    /* width: 50%; */
    margin: 0 20px;
    text-align: center;
  }

  .header_text h1 {
    /* font-size: 1.2em; */
    font-size: 24px;
  }

  .header_text p {
    /* font-size: 0.7em; */
    font-size: inherit;
  }

  .header_socials img {
    width: 18px;
  }

  .header_socials {
    right: 2%;
  }

  .first_section {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    flex-direction: column-reverse;
  }

  .first_sec_content h2 {
    font-size: 20px !important;
  }

  .first_sec_content p,
  .first_sec_content h6 {
    /* font-size: 0.8em !important; */
    font-size: inherit;
  }

  .second_section {
    padding: 0 !important;
    flex-direction: column;
    height: auto !important;
  }

  .second_sec_content {
    width: 80% !important;
    margin-left: 2rem;
    margin-top: 1rem;
  }

  .products_slider {
    width: 100% !important;
  }

  .second_sec_content h2 {
    font-size: 1.2rem !important;
  }

  .second_sec_content h3 {
    font-size: 1.15rem !important;
  }

  .second_sec_content p {
    font-size: 0.9rem !important;
  }
}



/*
==============
First SECTION
==============
*/
.first_sec_margin {
  /* margin-top: 10rem; */
}

/* .first_section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 75%;
}
.first_sec_images {
  width: 35%;
} */
.first_section {
  /* padding-left: 10rem;
  padding-right: 10rem; */
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin: 0 10%;
}

.first_sec_images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.first_sec_images img {
  width: 100%;
}

.horizontal_images {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
}

.horizontal_images img {
  height: 100%;
}

.first_sec_content h2 {
  font-family: Mulish;
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 1rem;
}

.first_sec_content h6 {
  font-size: 1em;
}

.first_sec_content p {
  color: grey;
}

.first_sec_content button {
  margin-top: 1rem;
}

.first_sec_content {
  /* width: 60%; */
}

/*
==============
SECOND SECTION
==============
*/
.second_section {
  background-color: rgb(252, 248, 243);
  display: flex;
  padding: 5rem;
  gap: 2rem;
  height: 70vh;
}

.second_sec_image {
  background-image: url("../../assets/Images/Marbre.png");
  background-size: cover;
  background-position: center;
  min-width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: end;
  /* height: 100vh; */
}

/* .title_container {
  background-color: rgba(255, 255, 255, 0.72);
  backdrop-filter: blur(0.5px);
  width: 40%;
  margin: 1.5em;
  padding-left: 1em;
  padding-bottom: 1em;
} */
.second_sec_content {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.second_sec_content h2 {
  margin: 0;
  font-family: kufam;
  color: #3a3a3a;
  font-size: 1.85rem;
}

.second_sec_content h3 {
  margin: 0;
  font-family: kufam;
  color: #3a3a3a;
  font-weight: 400;
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.second_sec_content p {
  margin-bottom: 1rem;
}

.products_slider {
  width: 65%;
  /* height: 300px; */
  /* height: fit-content; */
}

/* .marbre_arrow {
  position: absolute;
  width: 20%;
  right: -20%;
  bottom: 0%;
} */

@media (min-width: 769px) and (max-width: 950px) {
  .second_section {
    padding: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .second_sec_content h2 {
    font-size: 1.5rem !important;
    margin-bottom: 0.5;
  }

  .second_sec_content p {
    font-size: 0.8rem !important;
    text-align: justify;
  }

  .second_sec_content h3 {
    font-size: 1rem !important;
    margin-bottom: 0.5;
  }

  .home_title {
    margin-bottom: 3rem;
  }
}