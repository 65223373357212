.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  width: 27%;
  position: relative;
}



.product-image {
  width: 100%;
  /* height: auto; */
  aspect-ratio: 3/2;
  margin-bottom: 10px;
}

.discount-badge {
  position: absolute;
  top: 3%;
  right: 5%;
  background-color: red;
  color: white;
  padding: 8px 22px;
  font-size: 20px;
  font-weight: bold;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  border-radius: 0px 5px 5px 0px;
  -webkit-border-radius: 0px 5px 5px 0px;
  -moz-border-radius: 0px 5px 5px 0px;
  -ms-border-radius: 0px 5px 5px 0px;
  -o-border-radius: 0px 5px 5px 0px;
}

.discount-badge::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid white;
  transform: translateY(-50%) rotate(0deg);
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
}

.discount-badge::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.product-details {
  text-align: center;
  margin: 1rem;
}

.product-name {
  font-size: 1.2rem;
  margin: 0;
}

.product-price {
  font-size: 1.2em;
}

.original-price {
  text-decoration: line-through;
  margin-right: 10px;
  color: #888;
}

.promotional-price {
  font-weight: bold;
  /* color: #e53935; */
  color: #f38820;
}

/* .promoTag {
  width: 7rem;
  position: absolute;
  top: 0;
  right: 0;
} */
@media only screen and (max-width: 768px) {
  .product-card {
    width: 35%;
  }

  .product-details {
    margin: 0.25rem;
  }

  .discount-badge {
    font-size: 12px;
    padding: 6px 18px;
  }

  .discount-badge::after {
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-right: 12px solid white;
  }

  .product-name {
    font-size: 1rem;

  }

  .product-price {
    font-size: 1em;
  }
}

@media only screen and (max-width: 576px) {
  .product-name {
    font-size: .8rem;
    margin: 0;
  }

  .product-price {
    font-size: .8em;
  }
}

