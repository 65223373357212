.project-card {
  padding: 20px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  /* width: 30%; */
}

.project-image {
  width: 100%;
  height: auto;
}

.project-details {
  /* margin-top: 10px; */
  background-color: #f4f5f7;
  /* padding-left: 1rem; */
  padding: 2rem;
  text-align: center;
}

.project-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.project-city {
  font-size: 1rem;
  color: #666;
  margin-top: 5px;
  margin-bottom: 5px;
}

.project-description {
  font-size: 1rem;
  color: #555;
}

.overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.project-card:hover .overlay {
  opacity: 1;
}

.overlay-button {
  background-color: white;
  border: 2px solid transparent;
  border-radius: 5px;
  color: #f57f20;
  padding: 10px 20px;
  font-size: 0.75rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 2;
}

.overlay-button:hover {
  background-color: #f57f20;
  color: white;
}
