.footer-contact-form {
  position: relative;
  background-color: #fff;
  width: 40%;
  height: 40vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-field {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #023e8a;
  color: #023e8a;
  font-size: 12px;
  outline: none;
  margin-bottom: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.input-field::placeholder {
  color: #6f92c1;
}

.input-field-error {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #e60c0cdd;
  color: #e60c0cdd;
  font-size: 12px;
  outline: none;
  margin-bottom: 20px;
  font-family: sans-serif;
  font-weight: 600;
}

.input-field-error::placeholder {
  color: #e83b3bdd;
}

.footer-btn {
  background-color: #023e8a;
  color: #fff;
  border: 0;
  width: 90%;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.footer-contact-form h2 {
  color: #023e8a;
  font-family: Kufam;
}

.footer_rectangle {
  position: absolute;
  width: 10%;
  top: -2vh;
  right: -1vw;
}

@media only screen and (min-width: 000px) {}

@media only screen and (max-width: 768px) {
  .footer-contact-form {
    width: auto;
    height: auto;
    margin: 2rem;
  }

  .footer_rectangle {
    right: -3vw;
    top: -1.5vh;
  }
}